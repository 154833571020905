import { useState, useEffect } from 'react';
import _ from 'lodash';
import { QFilter, Option } from './QFilter';

interface FilterConfig {
  key: string;
  label: string;
  filteredKey: string;
  customOptions?: Option[];
  customHandleFilterChange?: (filteredKey: string, filteredData: unknown[], selected: Option) => unknown[];
}

interface QSharedFiltersProps {
  filtersConfig: FilterConfig[];
  originalData: unknown[];
  onFilteredData: (data: unknown[]) => void;
}

export const QSharedFilters = ({ filtersConfig, originalData, onFilteredData }: QSharedFiltersProps) => {
  // Initialize each filter with a default "All" option.
  const initialFilters: Record<string, Option> = filtersConfig.reduce((acc, filter) => {
    acc[filter.key] = { label: 'All', value: 'all' };
    return acc;
  }, {} as Record<string, Option>);

  const [selectedFilters, setSelectedFilters] = useState<Record<string, Option>>(initialFilters);

  // Update a filter's selection.
  const handleFilterChange = (key: string, value: Option) => {
    setSelectedFilters(prev => ({
      ...prev,
      [key]: value,
    }));
  };

  // For a given filter, compute available data by applying all *other* filters.
  const getAvailableDataForFilter = (currentFilterKey: string) => {
    let data = [...originalData];
    filtersConfig.forEach(filter => {
      if (filter.key !== currentFilterKey) {
        const selected = selectedFilters[filter.key];
        if (selected.value !== 'all') {
          if (filter.customHandleFilterChange) {
            data = filter.customHandleFilterChange(filter.filteredKey, data, selected);
          } else {
            data = data.filter(item => _.get(item, filter.filteredKey) === selected.value);
          }
        }
      }
    });
    return data;
  };

  // Apply all selected filters to the original data.
  useEffect(() => {
    const applyFilters = () => {
      let filteredData = [...originalData];

      filtersConfig.forEach(filter => {
        const { key, filteredKey, customHandleFilterChange } = filter;
        const selected = selectedFilters[key];

        if (selected.value === 'all') return; // Skip "All" selections.

        if (customHandleFilterChange) {
          filteredData = customHandleFilterChange(filteredKey, filteredData, selected);
        } else {
          filteredData = filteredData.filter(item => _.get(item, filteredKey) === selected.value);
        }
      });

      onFilteredData(filteredData);
    };

    applyFilters();
  }, [originalData, selectedFilters]);

  return (
    <div className="filters-container" style={{ display: 'flex', gap: '8px', padding: '16px' }}>
      {filtersConfig.map(filter => {
        // Compute available data by filtering originalData with all other filters.
        const availableData = getAvailableDataForFilter(filter.key);
        return (
          <QFilter
            key={filter.key}
            selectedValue={selectedFilters[filter.key]}
            onChange={value => handleFilterChange(filter.key, value)}
            label={filter.label}
            filteredKey={filter.filteredKey}
            // Pass the full data for global options and the availableData to mark disabled options.
            originalData={originalData}
            availableData={availableData}
            customOptions={filter.customOptions}
          />
        );
      })}
    </div>
  );
};
