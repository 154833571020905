import _ from 'lodash';

interface FilterByNestedPropertyParams<T> {
  data: T[];
  filteredKey: string;
  optionValues: string | string[];
  predicate: (entry: unknown) => boolean;
}

export const filterByNestedProperty = <T,>({ data, filteredKey, optionValues, predicate }: FilterByNestedPropertyParams<T>): T[] =>
  data.filter(item => {
    const value = _.get(item, filteredKey);
    if (!value) return false;

    if (Array.isArray(optionValues)) {
      return optionValues.some(option => value[option]?.some((entry: unknown) => predicate(entry)));
    }
    return value[optionValues]?.some((entry: unknown) => predicate(entry));
  });
