import _ from 'lodash';
import { QSelect } from '../QSelect/QSelect';

export interface Option {
  label: string;
  value: unknown;
  disabled?: boolean;
}

export interface QFilterProps {
  selectedValue: Option;
  onChange: (option: Option) => void;
  label: string;
  filteredKey: string;
  // Full dataset for this filter (global options)
  originalData?: unknown[];
  // Cascaded available data for this filter (used to disable unavailable options)
  availableData?: unknown[];
  customOptions?: Option[];
}

export const QFilter = ({
  selectedValue,
  onChange,
  label,
  filteredKey,
  originalData = [],
  availableData = [],
  customOptions = [],
}: QFilterProps) => {
  // Generate the full list of options from originalData or customOptions.
  const generateOptions = (): Option[] => {
    let fullOptions: Option[] = [];
    if (customOptions.length) {
      fullOptions = customOptions;
    } else if (originalData.length) {
      fullOptions = _.uniqBy(
        originalData.map(item => {
          const value = _.get(item, filteredKey);
          return {
            label: typeof value === 'string' ? _.capitalize(value) : 'Not specified',
            value,
          };
        }),
        'label',
      );
    }

    const getAvailableValues = () => {
      const values = new Set<string>();
      availableData.forEach(item => {
        const value = _.get(item, filteredKey);
        if (value && typeof value === 'object') {
          // Extract keys from the compliance object
          Object.keys(value).forEach(key => values.add(key));
        }
        values.add(value);
      });
      return values;
    };

    const availableValues = getAvailableValues();

    return fullOptions.map((option: any) => {
      if (option.value === undefined && availableValues.has(option.value)) {
        return { ...option, disabled: false };
      }
      return {
        ...option,
        disabled:
          option.value !== 'all' &&
          !availableValues.has(option.value) &&
          (!option.mapping || !option.mapping.some(item => availableValues.has(item))),
      };
    });
  };

  // Always include "All" at the top.
  const fullOptions = generateOptions();

  const options: Option[] = [{ label: 'All', value: 'all' }, ...fullOptions];

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selected = event.target.value;

    const option = options.find(opt => opt.value === selected) || { label: 'All', value: 'all' };

    onChange(option);
  };

  return (
    <div style={{ width: '200px' }}>
      <QSelect
        size="small"
        options={options}
        value={selectedValue.value}
        onChange={handleSelectChange}
        label={label}
        formControlProps={{ sx: { width: '180px' } }}
        selectIconProps={{ fontSize: 'medium' }}
        selectProps={{ sx: { backgroundColor: '#FFFFFF' } }}
      />
    </div>
  );
};
